<template>
    <div class="chat-box" id="chat-box">
      <Nav :title="title"></Nav>
      <div class="chat-window" id="chat-window">
        <div class="chat-messages" ref="chatMessages" id="chat-messages" v-show="!$store.state.loading" @scroll="handleScroll" >
          <div  id="chat-message-dialog" v-for="(msg, i) in messages" :key="i" :class="msg.sender === 'servicer' ? 'chat-right': 'chat-left'">
            <div class="chat-message left" v-if="msg.sender === 'servicer'">
              <div class="chat-message-text left" v-if="i === messages.length - 1 && i && isLastMsg">
                  <div ref="output" class="output-container">
                    <div class="output-html">
                      <div class="text-container">
                        <span>{{ msg.text }}</span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="chat-message-text left" v-else @click="copyToClipboard(msg.text)" :style="{'background': $store.state.userAgent === 'PC' ? '#f7f7f8': '#fff'}">
                <p>{{ msg.text }}</p>
              </div>
            </div>
            <div class="chat-message right" v-else>
              <span class="read" v-if="msg.status">已读</span>
              <span class="unread" v-else>未读</span>
              <div class="chat-message-text right" @click="copyToClipboard(msg.text)" :style="{'background': $store.state.userAgent === 'PC' ? '#f7f7f8': '#fff'}">
                <p>{{ msg.text }}</p>
              </div>
            </div>
          </div>
          <!-- 显示加载中的文本 -->
          <div v-if="isLoading">Loading...</div>
          <!-- 显示加载失败的文本 -->
          <div v-if="isLoadFailed">Failed to load.</div>
        </div>
        <div class="chat-input" id="chat-input">
          <textarea  v-model="inputText" type="text" class="message-input" @keyup.enter="sendMessage" placeholder="请输入您的问题..." 
                     ref="textarea" @input="adjustTextareaHeight" wrap="soft" rows="1" @keydown.enter.prevent></textarea>
          <button :class="inputText.length > 0 ? 'send-button-active' : 'send-button'" @click="sendMessage">发送</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { addCustomerServiceChat, queryCustomerServiceChat, updateChatStatusByServiceId } from "../../api/api";
  import Nav from "../../components/nav/index.vue"
  // const seo = require('../../../config/index')
  import {Toast} from 'vant'
  import WebSocketService from '@/utils/websocket';
  
  export default {
    name: "CustomerService",
    data() {
      return {
        messages: [],
        title: '我的客服',
        inputText: "",
        limit: 10,
        page: 1,
        loadingMoreMsg: false, // 上拉加载消息
        total: 0, // 总记录数
        isLastMsg: false, // 判断是否为实时的最新回答
        isLoading: false, // 是否正在加载数据
        isLoadFailed: false, // 是否加载数据失败
        cursorVisible: true,  // 控制光标的可见性
        isLogin: localStorage.getItem('token') ? true : false,
        websocket: null,
      };
    },
    components: {
      Nav,
    },
    created(){
      // 创建WebSocket连接
      this.websocket = new WebSocketService('http://172.20.10.2:10006');
      this.isLogin = localStorage.getItem('token') ? true : false
    },
    mounted(){
      if(this.isLogin){
        this.messages.length = 0
      } else {
        this.resetMsg()
      }
      this.getChatHistory()
      this.page = 1
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed(){
      window.removeEventListener(this.handleScroll)
    },
    methods: {
      async sendMessage() {
        if (this.inputText.trim().length > 0){
          // 向WebSocket服务器发送消息
          this.websocket.send('客服你好！');
          this.messages.push({ text: this.inputText, sender: "user" });
          let uid = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
          // 光标闪烁等待效果
          this.messages.push({ text: '', sender: "servicer" });
          this.isLastMsg = true
          let params = this.inputText
          this.inputText = "";
          // 滚动到底部
          this.scrollToEnd()
          this.messages.pop()
          // 远程保存聊天记录
          this.isLogin && addCustomerServiceChat({ sender: uid, receiver: 'servicer', message: params })
          this.isLastMsg = true
          this.$refs.textarea.focus()
        }
      },
      async getChatHistory(){
        let uid = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
        if(!this.loadingMoreMsg){
          this.$store.commit('Set_Loading_State', true)
        }
        let chatMessages = this.$refs.chatMessages;
        let oldItemsHeight = chatMessages.scrollHeight;
        this.isLoading = true; // 显示加载中的文本
        let res = await queryCustomerServiceChat({ limit: this.limit, page: this.page, uid: uid })
        this.total = res.data.data.count
        if(res.data.data.count === 0){
          if(this.messages.length === 0){ // 保证至少有引导语
            this.resetMsg()
          }
          addCustomerServiceChat({ sender: 'servicer', receiver: uid, message: this.messages[0].text }) // 远程保存聊天记录
        }
        let tempMsg = []
        res.data.data.rows.forEach(row=>{
          let temp = {}
          temp.serviceId = row.service_id
          temp.text = row.message // 聊天内容
          temp.sender = row.sender // 发送方
          temp.receiver = row.receiver // 接收方
          temp.status = row.status // 已读未读
          temp.createdAt = row.createdAt
          temp.updatedAt = row.updatedAt
          tempMsg.push(temp)
        })
        this.messages.splice(0,0,...tempMsg)
        this.$nextTick(()=>{
          if(this.page === 1){
            let chatMessages = this.$refs.chatMessages;
            chatMessages.scrollIntoView({ behavior: 'auto', block: 'end' });
          }
          this.isLoading = false // 停止加载中的状态
          this.page++ // 增加页码
          if(!this.loadingMoreMsg){
            this.scrollToEnd()
            this.$store.commit('Set_Loading_State', false)
          } else {
            const newItemsHeight = Number(chatMessages.scrollHeight) - Number(oldItemsHeight); // 计算新加载数据的高度
            if(this.$store.state.userAgent === 'Mobile'){ // 滚动条滚动到原来的位置加上新加载数据的高度
              chatMessages.scrollTo(0, chatMessages.scrollTop + newItemsHeight);
            } else {
              window.scrollTo(0, chatMessages.scrollTop + newItemsHeight);
            }
          }
          this.loadingMoreMsg = false
          this.updateStatus()
        })
      },

      // 更新当页的未读消息
      async updateStatus(){
        let serviceIdList = []
        for(let i = 0; i < this.limit; i++){
          if(!this.messages[i].status && this.messages[i].sender === 'servicer'){ // 未读的客服消息存入数组
            serviceIdList.push(this.messages[i].serviceId)
          }
        }
        serviceIdList.length > 0 && await updateChatStatusByServiceId({ serviceIdList: serviceIdList })
      },

      resetMsg(){
          this.messages = [{
            text: '您好,福五金客服为您服务！',
            sender: "servicer"
          }]
      },
      adjustTextareaHeight() {
        this.$refs.textarea.style.height = 'auto';
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight - 20 + 'px';
      },
      // scrollToEndPlus() {
      //     this.$nextTick(()=>{
      //       if(this.$store.state.userAgent === 'Mobile'){
      //         this.adjustTextareaHeight()
      //         let chatMessages = this.$refs.chatMessages;
      //         chatMessages.scrollTop = chatMessages.scrollHeight;
      //       } else {
      //         window.scrollTo(0, this.$refs.chatMessages.scrollHeight);
      //       }
      //     })
      // },
      scrollToEnd() {
          this.$nextTick(()=>{
            this.adjustTextareaHeight()
            let chatMessages = this.$refs.chatMessages;
            chatMessages.scrollTop = chatMessages.scrollHeight;
            window.scrollTo(0, this.$refs.chatMessages.scrollHeight);
          })
      },
      // 监听页面滚动事件
      handleScroll() {
        // 当滚动到顶部时，刷新聊天记录
        this.$nextTick(()=>{
          let UA = this.$store.state.userAgent
          let chatMessages = this.$refs.chatMessages;
          if (UA === 'Mobile' && chatMessages.scrollTop === 0 || UA === 'PC' && document.documentElement.scrollTop === 0) {
            // 还有没展示的消息，继续请求
            if(this.total > this.messages.length && !this.isLoading){
              this.loadingMoreMsg = true
              this.getChatHistory()
            }
          }
        })
      },
      // 复制到剪切板
      copyToClipboard(text) {
        const textarea = document.createElement("textarea"); // 创建一个textarea元素
        textarea.value = text; // 设置textarea的值
        document.body.appendChild(textarea); // 将textarea添加到DOM中
        textarea.select(); // 选中textarea中的文本
        document.execCommand("copy"); // 将选中的文本复制到剪贴板中
        document.body.removeChild(textarea); // 从DOM中移除textarea
        Toast('已复制');
      },
      resetScrollTop(){
          document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
          document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .chat-box {
    
  }
  .chat-window {
    position: fixed;
    z-index: -1;
    bottom: 150px;
    width: 100%;
    height: calc(100% - 150px);
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    .chat-messages {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      padding: 10px;
      overflow-y: scroll;
      margin: 50px 0 75px;
      background: #f3f3f3;
      .chat-left {
        display: flex;
        justify-content: right;
        margin-left: 45px;
      }
      .chat-right {
        display: flex;
        justify-content: left;
        margin-right: 45px;
      }
      .chat-message {
        display: flex;
        margin: 10px 0px;
        text-align: left;
        align-items: flex-end;
      }
  
      .chat-message.left {
        align-self: flex-start;
        font-size: 17px;
      }
  
      .chat-message.right {
        align-self: flex-end;
        font-size: 17px;
      }
  
      .chat-avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
  
      .chat-message-text.left {
        background-color: #fff;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        padding: 10px 15px;
        margin-left: 5px;
        white-space: pre-wrap;
        p {
          margin: 1px;
        }
      }
      .read {
        font-size: 12px;
        color: #969696;
        margin-right: 5px;
      }
      .unread {
        font-size: 12px;
        color: red;
        margin-right: 5px;
      }
      .chat-message-text.right {
        background-color: #fff;
        border-radius: 10px;
        border-bottom-right-radius: 0;
        padding: 10px 15px;
        margin-right: 5px;
        white-space: pre-wrap;
        p {
          margin: 1px;
        }
      }
    }
    .chat-input {
      padding: 10px;
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      border-top: 1px solid #ddd;
      padding: 10px 15px;
      background-image: linear-gradient(-20deg,#fc6076,#ff9a44);
      height: max-content;
      .message-input {
        flex: 1;
        border: none;
        outline: none;
        resize: none;
        font-size: 17px;
        padding: 10px 10px;
        line-height: 25px;
        margin: 10px 0;
        border-radius: 15px;
        background-color: #fff;
        height: 25px;
        overflow:visible;
      }
      .message-input:focus {
        background-color: #fff;
        box-shadow: 0 0 1px 1px #eee;
      }
  
      .send-button {
        margin-left: 10px;
        border: none;
        outline: none;
        font-size: 14px;
        color: #f3f3f3;
        border-radius: 20px;
        padding: 8px 16px;
        cursor: pointer;
        background-image: linear-gradient(-20deg,rgba(252, 96, 118, 0.5),rgba(255, 154, 68, 0.5));
      }
  
      .send-button-active {
        margin-left: 10px;
        border: none;
        outline: none;
        font-size: 14px;
        color: #eee;
        border-radius: 20px;
        padding: 8px 16px;
        cursor: pointer;
        background-image: linear-gradient(-20deg,rgba(252, 96, 118, 1),rgba(255, 154, 68, 1));
      }
    }
  }
  
  .output-html {
    position: relative;
  }
  
  .text-container {
    display: inline-block;
    position: relative;
  }
  
  .cursor {
    display: inline-block;
    width: 6px; /* 调整光标宽度 */
    height: 17px;
    line-height: 17px;
    background-color: #000;
    animation: blink-animation 0.9s infinite;
    margin-left: 5px; /* 添加光标与文字之间的间隔 */
    margin-top: 0px;
    vertical-align: middle; /* 光标垂直居中对齐 */
  }
  
  .cursor-waiting {
    display: inline-block;
    width: 6px; /* 调整光标宽度 */
    height: 17px;
    line-height: 17px;
    background-color: #000;
    animation: blink-animation 0.9s infinite;
    margin-left: 0px; /* 添加光标与文字之间的间隔 */
    margin-top: 0px;
    vertical-align: middle; /* 光标垂直居中对齐 */
  }
  
  @keyframes blink-animation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  // .cursor-waiting {
  //   position: relative;
  // }
  </style>>
    
import { render, staticRenderFns } from "./CustomerService.vue?vue&type=template&id=761645b2&scoped=true&"
import script from "./CustomerService.vue?vue&type=script&lang=js&"
export * from "./CustomerService.vue?vue&type=script&lang=js&"
import style0 from "./CustomerService.vue?vue&type=style&index=0&id=761645b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761645b2",
  null
  
)

export default component.exports
import io from 'socket.io-client';

class WebSocketService {
  constructor(url) {
    this.socket = io(url);
    this.socket.on('connect', this.onConnect.bind(this));
    this.socket.on('message', this.onMessage.bind(this));
    this.socket.on('disconnect', this.onDisconnect.bind(this));
    this.socket.on('error', this.onError.bind(this));
  }

  // WebSocket连接已经建立
  onConnect() {
    console.log('WebSocket connection opened');
  }

  // 处理WebSocket消息
  onMessage(message) {
    console.log('Received message:', message);
    // 在这里执行逻辑处理
  }

  // WebSocket连接已关闭
  onDisconnect(reason) {
    console.log('WebSocket connection closed', reason);
  }

  // 发生WebSocket错误
  onError(error) {
    console.error('WebSocket error', error);
  }

  // 发送数据到WebSocket服务器
  send(data) {
    this.socket.emit('message', data);
  }

  // 关闭WebSocket连接
  close() {
    this.socket.close();
  }
}

export default WebSocketService;

// // 连接到 WebSocket 服务器
// const socket = io('http://172.20.10.2:10006');
// // 发送消息给服务器
// socket.emit('message', '你好');
// 监听从服务器接收的消息
// socket.on('message', (message) => {
//     console.log('收到消息：', message);
// // 在 Vue 组件中更新界面，显示消息
// });